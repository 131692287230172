import React from 'react';
import {
  getBucket,
  getFilter,
  SearchContext
} from '../../context/searchContext';
import { ColorFilterButtons as ColorFilterButtonsComponent } from '../../components/filterButtons';
import { colors as colorMap } from '../../maps';
import { getKeyByValue } from '../../helpers/getKeyByValue';

export const ColorFilterButtons = () => {
  const { addFilter, state } = React.useContext(SearchContext);
  const colors = getBucket(state, 'color');
  if (!colors || colors.length === 1) {
    return null;
  }

  const activeFilter = getFilter(state, 'color');
  return (
    <ColorFilterButtonsComponent
      active={[colorMap[typeof activeFilter === 'string' ? activeFilter : '']]}
      colors={colors
        .map((color: any) => colorMap[color.key])
        .filter((color: any) => color)}
      onClick={selectedColor => {
        const colorKeys = getKeyByValue(colorMap, selectedColor);
        if (!colorKeys) {
          return;
        }
        addFilter('color', colorKeys);
      }}
    />
  );
};
