import * as React from 'react';
import { Shimmer } from '../shimmer';
import { ProductHeaderContainer } from '../productHeader/components';
import { ShopRowContainer as ShopRowContainerComponent } from '../../widgets/inArticleSingleProduct/components';
import { ShopRowPlaceholder } from './components';
import styled from 'styled-components';

const ShopRowContainer = styled(ShopRowContainerComponent)`
  flex: 2;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const SpecContainer = styled.div`
  flex: 1;
  margin-right: 10px;
  align-self: flex-start;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SingleWidgetPlaceholder = () => (
  <React.Fragment>
    <ProductHeaderContainer>
      <Shimmer
        width="180px"
        height="25px"
        style={{ display: 'block', marginBottom: '20px' }}
      />
      <Shimmer width="180px" height="35px" />
    </ProductHeaderContainer>
    <FlexContainer>
      <Shimmer style={{ marginRight: '10px' }} width="150px" height="180px" />
      <SpecContainer>
        <Shimmer width="180px" height="25px" />
      </SpecContainer>
      <ShopRowContainer>
        <Container>
          <ShopRowPlaceholder />
        </Container>
        <Container>
          <ShopRowPlaceholder />
        </Container>
        <Container>
          <ShopRowPlaceholder />
        </Container>
        <Container>
          <ShopRowPlaceholder />
        </Container>
        <Container>
          <ShopRowPlaceholder />
        </Container>
      </ShopRowContainer>
    </FlexContainer>
  </React.Fragment>
);
