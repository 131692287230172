import { Bucket } from '../../../types';

export const findBucket = (results: any, key: string): Bucket[] | null => {
  if (!results) {
    return null;
  }

  if (results.buckets) {
    return results.buckets;
  }

  if (!results[key]) {
    return null;
  }

  return findBucket(results[key], key);
};
