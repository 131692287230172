import styled from 'styled-components';

interface IProps {
  maxWidth: string;
}

export const Image = styled.img.attrs(({ maxWidth }: IProps) => ({
  loading: 'lazy',
  style: {
    maxWidth
  }
}))<IProps>``;
