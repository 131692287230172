import * as React from 'react';
import { ShowMoreButton, Container } from './components';

interface IProps {
  items: JSX.Element[];
}

export const MinMax: React.FC<IProps> = ({ items }) => {
  const [isOpen, setOpen] = React.useState(false);

  const getItems = (items: JSX.Element[]) => {
    if (items.length <= 5) {
      return items;
    }

    if (!isOpen) {
      return items.slice(0, 5);
    }

    return items;
  };

  const handleOnClick = () => setOpen(!isOpen);

  return (
    <Container>
      {getItems(items)}
      {items.length > 5 && (
        <ShowMoreButton onClick={handleOnClick}>
          {isOpen ? 'Toon minder prijzen ↑' : 'Toon meer prijzen ↓'}
        </ShowMoreButton>
      )}
    </Container>
  );
};
