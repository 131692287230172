import * as React from 'react';
import { ProductHeaderContainer } from '../productHeader/components';
import { Title } from '../title';

interface IProps {
  title: string | null;
  filtersComponent: React.ReactNode;
}

export const SimpleProductHeader: React.FC<IProps> = ({
  title,
  filtersComponent
}) => (
  <ProductHeaderContainer>
    <Title>{title}</Title>
    {filtersComponent}
  </ProductHeaderContainer>
);
