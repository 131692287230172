export type FilterValue = string | string[] | number | boolean | undefined;

export interface State {
  query: object | null;
  isLoading: boolean;
  hasError: boolean;
  results: object;
  requiredFilters: string[];
  filters: {
    [key: string]: FilterValue;
  };
}

export const initialState: State = {
  query: null,
  hasError: false,
  isLoading: true,
  requiredFilters: [],
  filters: {},
  results: {}
};

const SET_QUERY_ERROR = 'SET_QUERY_ERROR';
const UPDATE_RESULTS = 'UPDATE_RESULTS';
const UPDATE_QUERY = 'UPDATE_QUERY';
const ADD_FILTER = 'ADD_FILTER';
const SET_FILTERS = 'SET_FILTERS';
const RESET = 'RESET';
const SET_REQUIRED_FILTERS = 'SET_REQUIRED_FILTERS';

type SetError = {
  type: typeof SET_QUERY_ERROR;
};

export const setError = (): SetError => ({
  type: SET_QUERY_ERROR
});

type UpdateResults = {
  type: typeof UPDATE_RESULTS;
  payload: {
    results: object;
  };
};

export const updateResults = (results: object): UpdateResults => ({
  type: UPDATE_RESULTS,
  payload: {
    results
  }
});

type UpdateQuery = {
  type: typeof UPDATE_QUERY;
  payload: object;
};

export const updateQuery = (query: object): UpdateQuery => ({
  type: UPDATE_QUERY,
  payload: query
});

type AddFilter = {
  type: typeof ADD_FILTER;
  payload: { key: string; value: FilterValue };
};

export const addFilter = (key: string, value: FilterValue): AddFilter => ({
  type: ADD_FILTER,
  payload: { key, value }
});

type SetFilters = {
  type: typeof SET_FILTERS;
  payload: { [key: string]: FilterValue };
};

export const setFilters = (filters: {
  [key: string]: FilterValue;
}): SetFilters => ({
  type: SET_FILTERS,
  payload: filters
});

type Reset = {
  type: typeof RESET;
};

export const reset = (): Reset => ({
  type: RESET
});

type SetRequiredFilters = {
  type: typeof SET_REQUIRED_FILTERS;
  payload: string[];
};

export const setRequiredFilters = (filters: string[]): SetRequiredFilters => ({
  type: SET_REQUIRED_FILTERS,
  payload: filters
});

export const reducer = (
  state: State,
  action:
    | SetFilters
    | UpdateResults
    | UpdateQuery
    | SetError
    | AddFilter
    | Reset
    | SetRequiredFilters
): State => {
  switch (action.type) {
    case SET_REQUIRED_FILTERS: {
      return {
        ...state,
        requiredFilters: action.payload
      };
    }
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET: {
      return {
        ...initialState
      };
    }
    case ADD_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: value
        }
      };
    }
    case SET_QUERY_ERROR: {
      return {
        ...state,
        hasError: true,
        isLoading: false
      };
    }
    case UPDATE_QUERY: {
      return {
        ...state,
        query: action.payload
      };
    }
    case UPDATE_RESULTS: {
      // check which filters are applied
      const appliedFilters = Object.keys(state.filters);
      return {
        ...state,
        results: action.payload.results,
        // if the required filters are applied then we can change the loading state
        isLoading:
          state.requiredFilters.length > 0
            ? !appliedFilters.some(appliedFilter =>
                state.requiredFilters.includes(appliedFilter)
              )
            : false,
        hasError: false
      };
    }
  }
};
