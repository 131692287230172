import * as React from 'react';
import { Container, FilterButtonContainer, Button, Label } from './components';

interface IProps {
  label: string;
  onClick: (selected: string) => void;
  active?: Array<string>;
  items: Array<string>;
}

export const FilterButtons = ({
  label,
  onClick,
  items,
  active = []
}: IProps) => (
  <FilterButtonContainer>
    <Label>{label}</Label>
    <Container>
      {items.map((item: string, index: number) => (
        <Button
          key={index}
          active={active.includes(item)}
          onClick={() => onClick(item)}
        >
          {item}
        </Button>
      ))}
    </Container>
  </FilterButtonContainer>
);
