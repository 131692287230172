import { RoundingVariants } from '../types';

export const roundUpOrDown = (
  number: number,
  type: RoundingVariants
): number => {
  const roundfn = type === RoundingVariants.UP ? Math.ceil : Math.floor;

  return roundfn(number / 10000) * 10000;
};
