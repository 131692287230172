import styled from 'styled-components';
import { Price as PriceComponent } from '../price';
import { SponsoredAnchorButton as SponsoredAnchorButtonComponent } from '../buttons';
import { media } from '../../helpers';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  margin-bottom: 15px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ShopName = styled.span`
  flex: 2;
  font-size: 14px;
  font-weight: 500;
`;

export const Price = styled(PriceComponent)`
  flex: 1;
  font-size: 14px;
  text-align: right;
`;

export const ProductName = styled.div`
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const Button = styled(SponsoredAnchorButtonComponent)`
  font-size: 13px;
  padding: 7px 8px;
  ${media.sm(`
    padding: 4px 5px;
  `)}
`;

export const HeaderRow = styled(Row)`
  width: auto;
  padding: 8px 1px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
`;

export const HeaderShopName = styled(ShopName)`
  font-size: 12px;
  font-weight: 500;
`;
export const HeaderPrice = styled.span`
  flex: 1;
  font-size: 12px;
  text-align: right;
  font-weight: 500;
`;
export const HeaderAvailabilityBase = styled.span`
  flex: 1;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
`;
export const HeaderAvailability = styled(HeaderAvailabilityBase)`
  display: none;
  ${media.sm(`display: inline;`)}
`;
export const HeaderAvailabilitySm = styled(HeaderAvailabilityBase)`
  display: inline;
  ${media.sm(`display: none;`)}
`;
export const HeaderButton = styled(Button)`
  opacity: 0;
`;
