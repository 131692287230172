import * as React from 'react';
import {
  getAggregation,
  getFirstHit,
  hasError,
  hasFilter,
  hasResults,
  isLoading,
  SearchContext
} from '../../context/searchContext';
import Toggle from 'react-toggle';
import { SimpleProductHeader } from '../../components/simpleProductHeader';
import { ColorFilterButtons } from '../../searchComponents/colorFilterButtons';
import {
  Container,
  Image,
  ShopRowContainer,
  SpecsContainer
} from './components';
import { MinMax } from '../../components/minMax';
import { SingleWidgetPlaceholder } from '../../components/singleWidgetPlaceholder';
import { ShopRow } from '../../components/shopRow';
import { useGetShops, useSetInitialColor, useUpdateQuery } from './hooks';
import { Variants } from './types';
import { IShop } from '../../types';
import { ErrorMessage, Message } from '../../components/errorMessage';
import {
  HeaderRow,
  HeaderPrice,
  HeaderButton,
  HeaderShopName,
  HeaderAvailability,
  HeaderAvailabilitySm
} from '../../components/shopRow/components';
import { ToggleRow, ToggleLabel } from '../../components/toggleRow/components';
import { ChangeEvent, useEffect } from 'react';

interface IProps {
  variant: Variants;
  title?: string;
  withProductNames: boolean;
  allowRefurbishedToggle: boolean;
  defaultRufurbishedToggleState: boolean;
}

export const Widget = ({
  variant,
  title,
  withProductNames,
  allowRefurbishedToggle,
  defaultRufurbishedToggleState
}: IProps) => {
  const { state, performSearch, addFilter } = React.useContext(SearchContext);
  const [showRefurbishedToggle, setShowRefurbishedToggle] = React.useState(
    defaultRufurbishedToggleState
  );
  const firstItem = getFirstHit(state);

  useUpdateQuery(variant);
  useSetInitialColor(variant);
  const shops = useGetShops(variant);
  const updateHideRefurbished = (event: ChangeEvent<HTMLInputElement>) => {
    addFilter('isRefurbished', event.target.checked ? false : undefined);
  };

  useEffect(() => {
    // Never allow state changes if the toggle is not allowed to be shown
    if (!allowRefurbishedToggle) {
      return;
    }

    // Never allow the toggle to be removed if it became visible to prevent UI flickering
    if (showRefurbishedToggle) {
      return;
    }

    const aggregation = getAggregation(state, 'isRefurbished');

    // We can't make a decision if we don't have the aggregation yet
    if (!aggregation) {
      return;
    }

    setShowRefurbishedToggle(
      aggregation.buckets.length === 2 || hasFilter(state, 'isRefurbished')
    );
  }, [shops, state, showRefurbishedToggle, allowRefurbishedToggle]);

  if (isLoading(state)) {
    return <SingleWidgetPlaceholder />;
  }

  if (hasError(state)) {
    return <ErrorMessage onClick={performSearch} />;
  }

  if (!hasResults(state)) {
    return (
      <Message>
        Onze prijsvergelijker kan op dit moment helaas (nog) geen resultaten
        tonen.
      </Message>
    );
  }

  return (
    <React.Fragment>
      {firstItem && (
        <SimpleProductHeader
          title={title ? title : firstItem.name}
          filtersComponent={
            variant === Variants.FILTER ? <ColorFilterButtons /> : null
          }
        />
      )}
      <Container>
        {firstItem && (
          <React.Fragment>
            {firstItem.imageUrl && (
              <React.Fragment>
                <Image
                  maxWidth="150px"
                  alt={firstItem.modelType}
                  src={firstItem.imageUrl}
                />
                <SpecsContainer>
                  Koop nu de <strong>{title ? title : firstItem.name}</strong>{' '}
                  bij de volgende shops
                </SpecsContainer>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {shops && (
          <ShopRowContainer>
            {allowRefurbishedToggle && showRefurbishedToggle && (
              <ToggleRow>
                <label>
                  <ToggleLabel>Verberg refurbished</ToggleLabel>
                  <Toggle
                    icons={false}
                    onChange={updateHideRefurbished}
                    defaultChecked={defaultRufurbishedToggleState}
                  />
                </label>
              </ToggleRow>
            )}
            <HeaderRow>
              <HeaderShopName>Shop</HeaderShopName>
              <HeaderPrice>Totaalprijs</HeaderPrice>
              <HeaderAvailability>Voorraad</HeaderAvailability>
              <HeaderAvailabilitySm>
                <span role="img" aria-label="Winkelwagen emoji">
                  🛒
                </span>
              </HeaderAvailabilitySm>
              <HeaderButton>Bekijk »</HeaderButton>
            </HeaderRow>
            <MinMax
              items={shops.map((s: IShop, index: number) => (
                <ShopRow
                  {...s}
                  key={index}
                  variant={variant}
                  withProductName={withProductNames}
                />
              ))}
            />
          </ShopRowContainer>
        )}
      </Container>
    </React.Fragment>
  );
};
