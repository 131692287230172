import styled from 'styled-components';
import { media } from '../../helpers';

export const ProductInformation = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: space-around;
`;

export const ProductHeaderContainer = styled.div`
  padding: 18px 10px;
  background-color: #3e88cc;
`;

export const LargeProductFilters = styled.div`
  display: none;

  ${media.sm(`
    display: flex;
    flex-wrap: wrap;
  `)}
`;

export const SmallProductFilters = styled.div`
  margin-top: 10px;
  ${media.sm(`
    margin-left: 20px;
    display: none;
  `)};
`;

export const Properties = styled.div`
  margin-top: 5px;
  display: block;

  ${media.sm(`
    align-self: center;
    display: flex;
    margin-top: 0px;
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.sm(`
    flex-direction: row;
    align-items: center;
  `)}
`;

export const Image = styled.img`
  align-self: center;
  max-width: 90px;
`;
