import * as React from 'react';
import { Color } from '../color';
import { Container, Button, FilterButtonContainer, Label } from './components';

interface IFilterButtonProps {
  colors: Array<string>;
  onClick: (color: string) => void;
  active?: Array<string>;
}

export const ColorFilterButtons = ({
  colors,
  onClick,
  active = []
}: IFilterButtonProps) => (
  <FilterButtonContainer>
    <Label>Kleuropties:</Label>
    <Container>
      {colors.map((color: string, index) => (
        <Button
          key={index}
          active={active.includes(color)}
          onClick={() => onClick(color)}
        >
          <Color size="14" color={color} />
        </Button>
      ))}
    </Container>
  </FilterButtonContainer>
);
