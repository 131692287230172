import styled from 'styled-components';
import './toggle.css';

export const ToggleRow = styled.div`
  width: auto;
  text-align: right;
  padding: 8px 1px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
`;

export const ToggleLabel = styled.span`
  color: #7b7e7f;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
  margin-right: 10px;
`;
