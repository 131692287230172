import styled from 'styled-components';
import { Image as ImageContainer } from '../../components/image';
import { media } from '../../helpers';

export const Image = styled(ImageContainer)`
  margin-right: 10px;
  object-fit: contain;
  align-self: flex-start;
`;

export const Container = styled.div`
  color: #000;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #d9f1fd;
`;

export const ShopRowContainer = styled.div`
  display: flex;
  align-self: flex-start;
  min-width: 250px;
  flex: 2;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const SpecsContainer = styled.div`
  flex: 1;
  min-width: 200px;
  font-size: 12px;
  margin-top: 10px;

  ${media.sm(`
    margin-top: 0px;
    margin-right: 10px;
  `)}
`;
