import { Button } from '../buttons';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  onClick: () => void;
}

export const Message = styled.div`
  margin-bottom: 10px;
`;

const ErrorContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ErrorMessage = ({ onClick }: IProps) => (
  <ErrorContainer>
    <Message>Er is iets fout gegaan tijdens het laden</Message>
    <Button onClick={onClick}>Probeer opnieuw</Button>
  </ErrorContainer>
);
