import React from 'react';
import ReactDOM from 'react-dom';
import { InArticleSingleProduct } from './inArticleSingleProduct';
import { Variants } from './types';
import { parseWidgetProps } from '../../helpers';

export type RefurbishedPropValue = 'both' | 'hidden' | 'only' | 'none';

interface IWidgetProps {
  productSlug?: string;
  storageSize?: number;
  variant?: Variants;
  productIdentifiers?: string[];
  title?: string;
  // - 'both' (default) - show both new and refurbished products (toggle visible if results have refurbished products)
  // - 'hidden' - hide refurbished products by default           (toggle visible if results have refurbished products)
  // - 'only' - only show refurbished products                   (no toggle)
  // - 'none' - never show refurbished products                  (no toggle)
  refurbished?: RefurbishedPropValue;
}

const WIDGET_CLASSNAME = '.in-article-single-product-widget';

const attach = (el: Element) => {
  const props = parseWidgetProps<IWidgetProps>(el);

  if (!props.storageSize && !props.productSlug && !props.productIdentifiers) {
    throw Error('Please specify either a product or slug');
  }

  const parentElement = el.parentElement as HTMLElement;

  ReactDOM.render(
    <InArticleSingleProduct
      {...props}
      containerEl={parentElement}
      variant={props.variant || Variants.FILTER}
    />,
    el
  );
};

document.querySelectorAll(WIDGET_CLASSNAME).forEach(attach);

const detach = (el: Element) => {
  ReactDOM.unmountComponentAtNode(el);
};

// @ts-ignore
window.iCultureVergelijker = window.iCultureVergelijker || {};
// @ts-ignore
window.iCultureVergelijker.inArticleSingleProductWidget = {
  attach,
  detach,
  WIDGET_CLASSNAME
};
