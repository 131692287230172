import { Hit, IShop } from '../../types';

interface IArgs {
  _source: Hit;
}

export const mapShopsFromHits = (hit: IArgs): IShop => {
  const {
    _source: {
      name,
      price,
      shopName,
      priceTotal,
      shippingCost,
      affiliateUrl,
      deliveryTimeKey,
      isRefurbished
    }
  } = hit;
  return {
    url: affiliateUrl,
    shop: shopName,
    price: price,
    priceTotal: priceTotal,
    productName: name,
    availability: deliveryTimeKey,
    priceShipping: shippingCost,
    isRefurbished: isRefurbished
  };
};

export const mapShopsFromBucket = ({ shopName_hits }: any): IShop => {
  const [
    {
      _source: {
        name,
        price,
        shopName,
        priceTotal,
        affiliateUrl,
        shippingCost,
        deliveryTimeKey,
        isRefurbished
      }
    }
  ] = shopName_hits.hits.hits;

  return {
    url: affiliateUrl,
    shop: shopName,
    price: price,
    priceTotal: priceTotal,
    productName: name,
    availability: deliveryTimeKey,
    priceShipping: shippingCost,
    isRefurbished: isRefurbished
  };
};
