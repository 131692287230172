import * as React from 'react';
import {
  Row,
  ProductName,
  Container,
  ShopName,
  Price,
  Button
} from './components';
import { getFormattedPrice, getPromotionLabel } from '../../helpers';
import { Variants } from '../../widgets/inArticleSingleProduct/types';
import { AvailabilityStatusIcon } from '../availabilityStatusIcon';

interface IProps {
  url: string;
  shop: string;
  price: number;
  variant: Variants;
  priceTotal: number;
  productName: string;
  availability: string;
  priceShipping: number | null | undefined;
  withProductName: boolean;
}

function priceAndShippingTitle(
  price: number,
  shipping: number | null | undefined
): string {
  if (shipping === null || shipping === undefined) {
    return `€${getFormattedPrice(price)} + mogelijk verzendkosten`;
  } else if (shipping === 0) {
    return `€${getFormattedPrice(price)} + gratis verzenden`;
  } else {
    return `€${getFormattedPrice(price)} + €${getFormattedPrice(
      shipping
    )} verzendkosten`;
  }
}

export const ShopRow: React.FC<IProps> = ({
  shop,
  price,
  priceTotal,
  priceShipping,
  url,
  productName,
  variant,
  availability,
  withProductName
}) => (
  <Container>
    <Row>
      <ShopName>{shop}</ShopName>
      <Price
        href={url}
        price={priceTotal}
        explanation={priceAndShippingTitle(price, priceShipping)}
      />
      <AvailabilityStatusIcon status={availability} />
      <Button
        href={url}
        aria-label={getPromotionLabel({
          shopName: shop,
          productName
        })}
      >
        Bekijk »
      </Button>
    </Row>
    {withProductName && variant === Variants.LIST && (
      <ProductName>{productName}</ProductName>
    )}
  </Container>
);
