import * as React from 'react';
import styled from 'styled-components';

export const AvailabilityStatusIconComponent = styled.div`
  font-weight: 700;
  font-size: 14px;
  flex: 1;
  text-align: center;
`;

export const AvailabilityStatusIconComponentWrapper = styled.span`
  cursor: default;
  margin-left: 10px;
  margin-right: 10px;
`;

interface IAvailabilityStatusIcon {
  status: string;
}

export const AvailabilityStatusIcon: React.FC<IAvailabilityStatusIcon> = ({
  status
}) => (
  <AvailabilityStatusIconComponent>
    {(status === 'within_one_day' || status === undefined) && (
      <AvailabilityStatusIconComponentWrapper title="Levering verwacht binnen 1 dag">
        <span role="img" aria-label="Groene vierkantje">
          🟩
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'within_three_days' && (
      <AvailabilityStatusIconComponentWrapper title="Levering verwacht binnen 3 dagen">
        <span role="img" aria-label="Groen vierkantje">
          🟩
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'within_five_days' && (
      <AvailabilityStatusIconComponentWrapper title="Levering verwacht binnen 5 dagen">
        <span role="img" aria-label="Geel vierkantje">
          🟨
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'within_eight_days' && (
      <AvailabilityStatusIconComponentWrapper title="Levering verwacht binnen 8 dagen">
        <span role="img" aria-label="Geel vierkantje">
          🟨
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'within_twelve_days' && (
      <AvailabilityStatusIconComponentWrapper title="Levering verwacht binnen 12 dagen">
        <span role="img" aria-label="Geel vierkantje">
          🟨
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'more_than_twelve_days' && (
      <AvailabilityStatusIconComponentWrapper title="Levertijd langer dan 12 dagen">
        <span role="img" aria-label="Oranje vierkantje">
          🟧
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'pre_order' && (
      <AvailabilityStatusIconComponentWrapper title="Pre-order">
        <span role="img" aria-label="Oranje vierkantje">
          🟧
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {status === 'not_available' && (
      <AvailabilityStatusIconComponentWrapper title="Uitverkocht">
        <span role="img" aria-label="Rood vierkantje">
          🟥
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}

    {(status === 'unknown' || status === '') && (
      <AvailabilityStatusIconComponentWrapper title="Levertijd onbekend">
        <span role="img" aria-label="Rood vierkantje">
          🟥
        </span>
      </AvailabilityStatusIconComponentWrapper>
    )}
  </AvailabilityStatusIconComponent>
);
