import styled, { css } from 'styled-components';
import { FlexContainer as FlexContainerComponent } from '../flexContainer';

interface IButtonProps {
  active?: boolean;
}

export const Button = styled.button`
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  color: #4a90e2;
  border: 0;
  height: 34px;
  font-weight: 700;
  padding: 0 10px;
  background-color: white;
  font-size: 14px;
  border-right: 1px solid #dbeefb;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 34px;

  ${({ active }: IButtonProps) =>
    active &&
    css`
      background-color: #dbeefb;
      box-shadow: #bfc5cc 0px 1px 2px 0px inset;
    `}

  &:first-child {
    border-radius: 100px 0px 0px 100px;
  }

  &:last-child {
    border: none;
    border-radius: 0px 100px 100px 0px;
  }

  &:hover {
    color: white;
    background-color: #4a90e2;
  }

  &:only-child {
    border-radius: 25%;
    box-shadow: #dbeefb 1px 1px 3px 0;
  }
`;

export const Container = styled.span`
  display: inline-flex;
  box-shadow: #dbeefb 0 1px 3px 0;
  border-radius: 100px;
`;

export const FilterButtonContainer = styled(FlexContainerComponent)`
  display: inline-flex;
  flex-direction: column;
`;

export const Label = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 12px;
`;
