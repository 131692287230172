export const performElasticQuery = async (query: object, headers: object) => {
  const result = await fetch(
    `${process.env.REACT_APP_ELASTIC_SEARCH_URL}/search`,
    {
      body: JSON.stringify(query),
      method: 'POST',
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );

  return await result.json();
};
