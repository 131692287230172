import { State } from './reducer';
import { get } from 'lodash-es';
import { findBucket } from './helpers';

export const isLoading = (state: State) => state.isLoading;

export const getFilters = (state: State) => state.filters;

export const getHits = (state: State) => get(state.results, 'hits.hits') || [];

export const getFirstHit = (state: State) => get(getHits(state), '[0]._source');

export const getAggregation = (state: State, key: string) =>
  get(state.results, `aggregations.${key}`);

export const getFilter = (state: State, key: string) => state.filters[key];

export const hasFilter = (state: State, key: string) =>
  state.filters[key] !== undefined;

export const getBucket = (state: State, key: string) =>
  findBucket(getAggregation(state, key), key) || [];

export const hasResults = (state: State) => getHits(state).length > 0;

export const hasError = (state: State) => state.hasError;
