export const colors: { [key: string]: string } = {
  Zilver: '#E4E4E2',
  Spacegrijs: '#25282A',
  Goud: '#DFCCB7',
  Roségoud: '#E6C7C2',
  Zwart: '#2E3033',
  Blauw: '#5EB0E5',
  Geel: '#F3D060',
  Rood: '#A5282C',
  Koraal: '#FD6F60',
  Wit: '#F3F3F3',
  Gitzwart: '#000'
};
