import { Bucket } from '../../types';

export const sortBucketNumerically = (a: Bucket, b: Bucket) => {
  const firstItem = parseFloat(a.key_as_string);
  const secondItem = parseFloat(b.key_as_string);

  if (firstItem < secondItem) {
    return -1;
  }

  if (firstItem > secondItem) {
    return 1;
  }

  return 0;
};
