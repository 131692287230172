import {
  getBucket,
  getFilters,
  getFirstHit,
  getHits,
  hasFilter,
  SearchContext
} from '../../context/searchContext';
import * as React from 'react';
import { Variants } from './types';
import { getFilterQuery, getListQuery } from './getQuery';
import { mapShopsFromBucket, mapShopsFromHits } from './helpers';
import { IShop } from '../../types';

/**
 * Sets the initial color when it's not selected
 * This can only be used in the filter variant
 */
export let useSetInitialColor: (variant: Variants) => void;
useSetInitialColor = (variant: Variants) => {
  const { addFilter, state } = React.useContext(SearchContext);
  React.useEffect(() => {
    // when the variant isn't filter we'll cancel this hook
    if (variant !== Variants.FILTER) {
      return;
    }

    if (hasFilter(state, 'color')) {
      return;
    }
    const firstHit = getFirstHit(state);
    if (!firstHit) {
      return;
    }
    addFilter('color', firstHit.color);
  }, [addFilter, state, variant]);
};

/**
 * Updates the query based on the filter parameters which will cause a new query to be executed
 */
export const useUpdateQuery = (variant: Variants) => {
  const { setQuery, state } = React.useContext(SearchContext);
  const filters = getFilters(state);
  React.useEffect(() => {
    if (
      variant === Variants.FILTER &&
      filters.productSlug &&
      filters.storageSize
    ) {
      setQuery(getFilterQuery(filters as any));
      return;
    }

    if (variant === Variants.LIST) {
      setQuery(getListQuery(filters as any));
    }
  }, [filters, setQuery, variant]);
};

/**
 * Gets the shops from either hits or buckets (depends on the widget variant)
 */
export const useGetShops = (variant: Variants): IShop[] => {
  const { state } = React.useContext(SearchContext);
  if (variant === Variants.FILTER) {
    return getBucket(state, 'shops').map(mapShopsFromBucket);
  }
  return getHits(state).map(mapShopsFromHits);
};
