import { Bucket } from '../../types';

export const sortBucketAlphabetically = (a: Bucket, b: Bucket) => {
  if (a.key < b.key) {
    return -1;
  }
  if (a.key > b.key) {
    return 1;
  }
  return 0;
};
