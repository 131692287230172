import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';
import { Button } from '../buttons';
import { media } from '../../helpers';

export const ShowMoreButton = styled(Button)`
  align-self: center;
  padding: 4px 5px;
  ${media.sm(`
    padding: 4px 5px;
  `)}
`;

export const Container = styled(FlexContainer)`
  flex-direction: column;
  padding: 0 10px;
`;
