import styled, { css, keyframes } from 'styled-components';

const shimmerKeyframe = keyframes`
  0% {
  background-position: -468px 0;
  }
  100% {
  background-position: 468px 0;
  }
`;

interface IProps {
  width: string;
  height: string;
}

export const Shimmer = styled.div`
  background: #f6f7f8
    linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  display: inline-block;
  border-radius: 3px;
  background-size: 800px 100%;
  animation: ${shimmerKeyframe} 2.5s ease infinite;
  ${({ width, height }: IProps) => css`
    width: ${width};
    height: ${height};
  `}
`;
