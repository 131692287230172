import * as React from 'react';
import styled from 'styled-components';
import { getFormattedPrice, media } from '../../helpers';

export const PriceComponent = styled.span`
  color: #048bce;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
`;

export const PriceLink = styled.a`
  color: #048bce;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
`;

const PriceExplanationComponent = styled.span`
  color: #aaa;
  cursor: help;
  display: none;
  font-size: 11px;
  font-weight: normal;
  ${media.sm(`
      display: inline;
    `)}
`;

interface IPrice {
  price: number;
  className?: string;
  href: string;
  tooltipId?: string;
  explanation?: string;
}

export const Price: React.FC<IPrice> = ({
  price,
  className,
  href,
  explanation = undefined
}) => (
  <PriceComponent className={className}>
    <PriceLink href={href} rel="sponsored noopener nofollow" target="_blank">
      €{getFormattedPrice(price)}
    </PriceLink>
    {explanation && (
      <PriceExplanationComponent title={explanation}>
        {' '}
        [?]
      </PriceExplanationComponent>
    )}
  </PriceComponent>
);
