import * as React from 'react';
import { SearchContextProvider } from '../../context';
import { Widget } from './widget';
import { Variants } from './types';
import { filterEmptyKeys } from '../../helpers';
import { ThemeProvider } from 'styled-components';
import { useGetContainerWidth } from '../../hooks';
import { RefurbishedPropValue } from './index';

const EAN_REGEX = new RegExp('^(\\d{8,16})$');

interface IProps {
  storageSize?: number;
  productSlug?: string;
  color?: string;
  productIdentifiers?: string[];
  variant: Variants;
  title?: string;
  containerEl: HTMLElement;
  singleProduct?: boolean;
  refurbished?: RefurbishedPropValue;
}

export const InArticleSingleProduct = ({
  containerEl,
  storageSize,
  productSlug,
  color,
  productIdentifiers,
  variant,
  title,
  singleProduct,
  refurbished
}: IProps) => {
  const width = useGetContainerWidth(containerEl);

  const uniqueProductIdentifiers = productIdentifiers
    ? [
        ...new Set(
          productIdentifiers.map((id: string) => {
            // We take a wide range here since it's possible someone prefixed too many or too little 0's
            if (EAN_REGEX.test(id)) {
              // Identifiers are supposed to be EANs so we pad them with 0s to alway be 13 chars which is the max EAN length
              // @see: https://support.google.com/merchants/answer/6219078?hl=en
              return id.slice(-13).padStart(13, '0');
            }

            return id;
          })
        )
      ]
    : [];

  // We only want to show product names if we have more than a single product listed
  // There is a special case for 2 identifiers where 1 is a EAN and the other is not (EAN + Apple SKU), here we assume it's also 1 product
  const withProductNames =
    uniqueProductIdentifiers.length === 2
      ? singleProduct !== true &&
        uniqueProductIdentifiers.filter(id => EAN_REGEX.test(id)).length !== 1
      : singleProduct !== true && uniqueProductIdentifiers.length !== 1;

  let isRefurbishedFilter: boolean | undefined;
  let showRefurbishedToggle: boolean;
  let defaultRufurbishedToggleState: boolean;

  switch (refurbished) {
    case 'only':
      isRefurbishedFilter = true;
      showRefurbishedToggle = false;
      defaultRufurbishedToggleState = false;
      break;
    case 'none':
      isRefurbishedFilter = false;
      showRefurbishedToggle = false;
      defaultRufurbishedToggleState = true;
      break;
    case 'hidden':
      isRefurbishedFilter = false;
      showRefurbishedToggle = true;
      defaultRufurbishedToggleState = true;
      break;
    case 'both':
    default:
      isRefurbishedFilter = undefined;
      showRefurbishedToggle = true;
      defaultRufurbishedToggleState = false;
  }

  return (
    <ThemeProvider
      theme={{
        containerWidth: width
      }}
    >
      <SearchContextProvider
        productIdentifiers={uniqueProductIdentifiers}
        requiredFilters={variant === Variants.FILTER ? ['color'] : []}
        initialFilters={filterEmptyKeys({
          productIdentifiers: uniqueProductIdentifiers,
          isRefurbished: isRefurbishedFilter,
          storageSize,
          productSlug,
          color
        })}
      >
        <Widget
          title={title}
          variant={variant}
          withProductNames={withProductNames}
          allowRefurbishedToggle={showRefurbishedToggle}
          defaultRufurbishedToggleState={defaultRufurbishedToggleState}
        />
      </SearchContextProvider>
    </ThemeProvider>
  );
};
