export const getFormattedPrice = (price: number, decimals?: boolean) => {
  const rest = price % 100;
  const priceString = (price / 100).toString();

  if (rest === 0) {
    // Check if there is no rest then append the 0's
    if (decimals === false) {
      return priceString;
    }
    return `${priceString},-`;
  }

  const numbersBeforeComma = priceString.slice(0, priceString.indexOf('.'));

  if (decimals === false) {
    return numbersBeforeComma;
  }

  if (rest % 100 < 9) {
    // Check if rest is < decem so either .09/.08/.07 and add a 0 if so.
    return `${numbersBeforeComma},0${rest}`;
  }

  return `${numbersBeforeComma},${rest}`; // Case of 2 decimals and no 0
};
