import React from 'react';
import { performElasticQuery } from './helpers';
import {
  addFilter as addFilterAction,
  FilterValue,
  initialState,
  reducer,
  reset as resetAction,
  setError,
  setFilters as setFiltersAction,
  setRequiredFilters as setRequiredFiltersAction,
  State,
  updateQuery,
  updateResults
} from './reducer';

export interface ISearchContext {
  state: State;
  addFilter: (key: string, value: string | boolean | undefined) => void;
  setFilters: (filters: { [key: string]: FilterValue }) => void;
  setQuery: (query: object) => void;
  performSearch: () => void;
  reset: () => void;
  setRequiredFilters: (filters: string[]) => void;
}

export const SearchContext = React.createContext<ISearchContext>(
  {} as ISearchContext
);

interface IProps {
  children: React.ReactNode;
  initialFilters?: {
    [key: string]: FilterValue;
  };
  requiredFilters?: string[] | undefined;
  productIdentifiers?: string[] | undefined;
}

// @TODO we could wrap this in a create function to pass down generics
export const SearchContextProvider = ({
  children,
  initialFilters,
  requiredFilters,
  productIdentifiers
}: IProps) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    filters: initialFilters || {},
    requiredFilters: requiredFilters || []
  });

  const performSearch = React.useCallback(async () => {
    if (!state.query) {
      return;
    }

    const headers: any = {};

    if (productIdentifiers && productIdentifiers.length > 0) {
      headers['X-Product-Identifiers'] = productIdentifiers.join(',');
    }

    try {
      const results = await performElasticQuery(state.query, headers);
      dispatch(updateResults(results));
    } catch (error) {
      console.error(error);
      dispatch(setError());
    }
  }, [state.query, productIdentifiers]);

  React.useEffect(() => {
    performSearch();
  }, [performSearch]);

  const setQuery = React.useCallback((query: object) => {
    dispatch(updateQuery(query));
  }, []);

  const addFilter = React.useCallback((key: string, value: FilterValue) => {
    dispatch(addFilterAction(key, value));
  }, []);

  const setFilters = React.useCallback(
    (filters: { [key: string]: FilterValue }) => {
      dispatch(setFiltersAction(filters));
    },
    []
  );

  const setRequiredFilters = React.useCallback((filters: string[]) => {
    dispatch(setRequiredFiltersAction(filters));
  }, []);

  const reset = React.useCallback(() => {
    dispatch(resetAction());
  }, []);

  return (
    <SearchContext.Provider
      value={{
        reset,
        state: state,
        addFilter,
        setQuery,
        performSearch,
        setFilters,
        setRequiredFilters
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
