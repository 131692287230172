import * as React from 'react';
import { throttle } from 'lodash-es';

let throttledResize: EventListener;
export const useGetContainerWidth = (containerEl: HTMLElement) => {
  const [width, setWidth] = React.useState<number | null>(null);
  React.useEffect(() => {
    throttledResize = throttle(() => setWidth(containerEl.clientWidth), 300);
    window.addEventListener('resize', throttledResize);
    setWidth(containerEl.clientWidth);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return width;
};
