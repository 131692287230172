import * as React from 'react';
import { Shimmer } from '../shimmer';

export const ShopRowPlaceholder = () => (
  <React.Fragment>
    <Shimmer width="85px" height="22px" />
    <Shimmer width="75px" height="22px" />
    <Shimmer width="40px" height="22px" />
  </React.Fragment>
);
