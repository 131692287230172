import styled, { css } from 'styled-components';

interface IProps {
  size: string;
  color: string;
}

export const Color = styled.div`
  display: inline-block;
  ${({ size, color }: IProps) => css`
    height: ${size}px;
    width: ${size}px;
    background-color: ${color};
  `}
  border-radius: 2px;
`;
