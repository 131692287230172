import { FilterValue } from '../context/searchContext/reducer';

export const filterEmptyKeys = (obj: { [key: string]: FilterValue }) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value !== 'boolean' && !value) {
      return acc;
    }

    return {
      ...acc,
      [key]: value
    };
  }, {});
