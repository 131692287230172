export const getValuesByKeys = (
  object: { [key: string]: string },
  values: Array<string>
): Array<string> | undefined => {
  return Object.entries(object).reduce((acc: Array<string>, [key, value]) => {
    if (!values.includes(key)) {
      return acc;
    }
    return [...acc, value];
  }, []);
};
