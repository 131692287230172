import styled, { css } from 'styled-components';
import { media } from '../../helpers';

const buttonStyle = css`
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  background-color: #4a90e2;
  padding: 7px;
  font-size: 12px;
  border: none;
  font-variant: all-small-caps;
  ${media.sm(`
    padding: 10px 12px;
  `)}
`;

const style = {
  color: 'white'
};

export const Button = styled.button.attrs({ style })`
  ${buttonStyle}
`;

export const AnchorButton = styled.a.attrs({
  style
})`
  ${buttonStyle}
`;

export const SponsoredAnchorButton = styled(AnchorButton).attrs({
  rel: 'sponsored noopener nofollow',
  target: '_blank',
  style
})``;
